import React from 'react'
import { Layout } from '../../plugins/gatsby-theme-orga-posts'

const NotFound = () => {
  return (
    <Layout>
      <div>Not Found</div>
    </Layout>
  )
}

export default NotFound
