// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---plugins-gatsby-theme-orga-posts-core-src-templates-home-query-js": () => import("./../../../plugins/gatsby-theme-orga-posts-core/src/templates/home-query.js" /* webpackChunkName: "component---plugins-gatsby-theme-orga-posts-core-src-templates-home-query-js" */),
  "component---plugins-gatsby-theme-orga-posts-core-src-templates-post-query-js": () => import("./../../../plugins/gatsby-theme-orga-posts-core/src/templates/post-query.js" /* webpackChunkName: "component---plugins-gatsby-theme-orga-posts-core-src-templates-post-query-js" */),
  "component---plugins-gatsby-theme-orga-posts-core-src-templates-posts-query-js": () => import("./../../../plugins/gatsby-theme-orga-posts-core/src/templates/posts-query.js" /* webpackChunkName: "component---plugins-gatsby-theme-orga-posts-core-src-templates-posts-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

